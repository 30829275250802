import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AktuellesKlartextPage = () => (

  <Layout>
    <SEO title="AktuellesKlartext" />

    <div className="content1">
      <div>
        <h1>Aktuelle Termine der Tanzkurse &emsp; Stand:&nbsp;{new Date().getFullYear()}<br/>und andere Informationen </h1>
        <h2>&emsp;------&emsp;</h2>
          <p>&emsp;</p>

          <h3>2020-02-23 &emsp; 23. Januar 2020</h3>
            <h4>News / Neuigkeiten</h4>
              <p>
                Der Aufbau der Tanzkonzepte steht.<br />
                Aktuell wird noch an einem Buch geschrieben und einige passende Tanzräume gesucht.<br/>
                Und natürlich werden die fehlenden Internetseiten erstellt.
              </p>

        <hr />

        <p>---</p>
      </div>

      <div className="b-data">
        {/**Hier vergangene Termine hinein kopieren */}

          <h3>2019-08-24+25 &emsp; 24.+25. August 2019</h3>
            <h4>Line Dance: Schnuppertreff für Interessierte => Anfängerkurs</h4>
              <p>
                für Teilnehmer der SoCraTes 2019 <br/>
                in Soltau
              </p>
      </div>
    </div>

  </Layout>
)

export default AktuellesKlartextPage
